import {useDispatch, useSelector} from "react-redux";
import {selectAllUserData} from "../../redux/slice/userSlice";
import {getClubListFetchStatus, selectClubsList} from "../../redux/slice/clubDataSlice";
import {
    getDistrictCouncilMembersDataStatus,
    getMembershipDataStatus,
    selectDistrictCouncilMembersData,
    selectMembershipData
} from "../../redux/slice/membershipSlice";
import {
    getNewReportStatus,
    getSelectedReportStatus,
    selectSelectedReport,
    setSelectedReport, setSelectedReportStatus
} from "../../redux/slice/reportingSlice";
import {useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Checkbox,
    Input,
    Select,
    SelectItem, DatePicker
} from "@nextui-org/react";
import {ToastContainer} from "react-toastify";
import {DD_MEETING_TYPE} from "../../../constants/dropdownList";
import {applicationRTESection} from "../../council57/ApplicationRTESection";
import {
    getDistrictCouncilMembersData, getMembershipData,
} from "../../../services/MembershipService";
import {getLocalTimeZone, now, parseAbsoluteToLocal} from "@internationalized/date";
import {addMinutesOfMeeting, updateMeetingForClub} from "../../../services/MeetingsServices";
import {getImageFromDrive} from "../../common/DetailsViewHelpers";

export default function ReportMeetings() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector(selectAllUserData);

    const clubsList = useSelector(selectClubsList);
    const fetchClubListStatus = useSelector(getClubListFetchStatus);

    const activeMembersList = useSelector(selectMembershipData).filter(members => members.membershipStatus === "Active");
    const guestMembersList = useSelector(selectMembershipData).filter(members => members.membershipStatus === "Guest");
    const membershipStatus = useSelector(getMembershipDataStatus);

    const dcmDataList = useSelector(selectDistrictCouncilMembersData);
    const dcmDataStatus = useSelector(getDistrictCouncilMembersDataStatus);

    const editReport = useSelector(selectSelectedReport);
    const editedReportStatus = useSelector(getSelectedReportStatus);
    const selectedRecordType = useSelector(getSelectedReportStatus);
    const newReportStatus = useSelector(getNewReportStatus);

    const [venue, setVenue] = useState(editReport?.venue ? editReport.venue : "");
    const [meetingType, setMeetingType] = useState(editReport?.meetingType ? new Set().add(editReport.meetingType) : new Set([]));
    const [startTime, setStartTime] = useState(editReport?.startTime ? parseAbsoluteToLocal(new Date(editReport.startTime.replace("T", " ")).toISOString()) : now(getLocalTimeZone()));
    const [endTime, setEndTime] = useState(editReport?.endTime ? parseAbsoluteToLocal(new Date(editReport.endTime.replace("T", " ")).toISOString()) : now(getLocalTimeZone()));
    const [isDraftReport, setIsDraftReport] = useState(editReport?.adminData?.reportStatus === "Draft");

    const [minutesOfMeeting, setMinutesOfMeeting] = useState(editReport?.minutes ? editReport.minutes : "");

    const [income, setIncome] = useState(editReport?.meetingFinances?.income ? editReport.meetingFinances.income : 0);
    const [expense, setExpense] = useState(editReport?.meetingFinances?.expense ? editReport.meetingFinances.expense : 0);

    const [jointEventIncome, setJointEventIncome] = useState(editReport?.meetingFinances?.jointEventFinances?.income ? editReport.meetingFinances.jointEventFinances.income : 0);
    const [jointEventExpense, setJointEventExpense] = useState(editReport?.meetingFinances?.jointEventFinances?.expense ? editReport.meetingFinances.jointEventFinances.expense : 0);

    const [activeAttendees, setActiveAttendees] = useState(editReport?.attendance?.homeClubActiveMembers ? new Set(editReport.attendance.homeClubActiveMembers.map(String)) : new Set([]));
    const [guestAttendees, setGuestAttendees] = useState(editReport?.attendance?.homeClubGuestMembers ? new Set(editReport.attendance.homeClubGuestMembers.map(String)) : new Set([]));
    const [dcmAttendees, setDcmAttendees] = useState(editReport?.attendance?.dcms ? new Set(editReport.attendance.dcms.map(String)) : new Set([]));
    const [rotariansCount, setRotariansCount] = useState(editReport?.attendance?.rotarians ? editReport.attendance.rotarians : 0);
    const [otherGuestsCount, setOtherGuestsCount] = useState(editReport?.attendance?.otherGuests ? editReport.attendance.otherGuests : 0);
    const [alumnusCount, setAlumnusCount] = useState(editReport?.attendance?.alumnus ? editReport.attendance.alumnus : 0);
    const [otherClubMembersCount, setOtherClubMembersCount] = useState(editReport?.attendance?.otherClubMembers ? editReport.attendance.otherClubMembers : 0);
    const [otherPisCount, setOtherPisCount] = useState(editReport?.attendance?.otherPis ? editReport.attendance.otherPis : 0);
    const [otherDistrictRotaractorsCount, setOtherDistrictRotaractorsCount] = useState(editReport?.attendance?.otherDistrictRotaractors ? editReport.attendance.otherDistrictRotaractors : 0);
    const [interactorsCount, setInteractorsCount] = useState(editReport?.attendance?.interactors ? editReport.attendance.interactors : 0);

    const [coverImageURL, setCoverImageURL] = useState(editReport?.supportDocuments?.coverImageURL ? editReport.supportDocuments.coverImageURL : "");
    const [supportDocumentsURL, setSupportDocumentsURL] = useState(editReport?.supportDocuments?.supportDocumentsURL ? editReport.supportDocuments.supportDocumentsURL : "");

    const [jointMeetingClubs, setJointMeetingClubs] = useState(editReport?.jointMeetingsClubs ? new Set(editReport.jointEventClubs.map(String)) : new Set([]));


    useEffect(() => {
        if (window.location.pathname.toString().includes("/edit-report") && (null === editReport || undefined === editReport))
            navigate("..");

        if (membershipStatus === "idle")
            getMembershipData(dispatch, user).then(() => {
                setActiveAttendees(new Set(editReport?.attendance?.homeClubActiveMembers))
            });

        if (dcmDataStatus !== "completed")
            getDistrictCouncilMembersData(dispatch, user);
    }, []);

    const validateCoverImageUrl = (value) => value.includes("https://drive.google.com/file/d/") || value.includes("https://drive.google.com/uc?export=view&id=");

    const isInvalidCoverImage = React.useMemo(() => {
        if (coverImageURL === "") return false;

        return !validateCoverImageUrl(coverImageURL);
    }, [coverImageURL]);

    const handleUpdateForMoM = (value, editor) => {
        setMinutesOfMeeting(value);
    };

    function clearTaskAndCloseAmbassadorialScreen() {
        dispatch(setSelectedReport({
            report: null,
            reportType: "Meeting"
        }));
        dispatch(setSelectedReportStatus('idle'));
        navigate("..");
    }

    async function submitForm(e) {
        e.preventDefault();

        let attendanceObjValue = {
            homeClubActiveMembers: [...activeAttendees].map(Number),
            homeClubGuestMembers: [...guestAttendees].map(Number),
            dcms: [...dcmAttendees].map(Number),
            rotarians: Number(rotariansCount),
            otherGuests: Number(otherGuestsCount),
            alumnus: Number(alumnusCount),
            otherClubMembers: Number(otherClubMembersCount),
            otherPis: Number(otherPisCount),
            otherDistrictRotaractors: Number(otherDistrictRotaractorsCount),
            interactors: Number(interactorsCount),
        };

        let supportDocumentsObjValue = {
            coverImageURL: coverImageURL,
            supportDocumentsURL: supportDocumentsURL.trim(),
        };

        let requestBody = {
            venue: venue.trim(),
            startTime: startTime.toString().split(".")[0].split("+")[0],
            endTime: endTime.toString().split(".")[0].split("+")[0],
            isDraft: isDraftReport,
            meetingType: meetingType.keys().next().value,
            minutes: minutesOfMeeting,
            eventFinances: {
                income: Number(income),
                expense: Number(expense),
                profit: Number(income) >= Number(expense) ? Number(income) - Number(expense) : 0,
                loss: Number(income) < Number(expense) ? Number(expense) - Number(income) : 0,
            },
            attendance: attendanceObjValue,
            supportDocuments: supportDocumentsObjValue,
        }

        if (meetingType.keys().next().value === "Joint Meeting") {
            requestBody.eventFinances["jointEventFinances"] = {
                income: Number(jointEventIncome),
                expense: Number(jointEventExpense),
                profit: Number(jointEventIncome) >= Number(jointEventExpense) ? Number(jointEventIncome) - Number(jointEventExpense) : 0,
                loss: Number(jointEventIncome) < Number(jointEventExpense) ? Number(jointEventIncome) - Number(jointEventExpense) : 0,
            }
            requestBody["jointEventClubs"] = [...jointMeetingClubs];
        }

        if (null === editReport && undefined === editReport?.meetingId)
            addMinutesOfMeeting(dispatch, user, requestBody, navigate);
        else
            updateMeetingForClub(dispatch, user, requestBody, editReport.meetingId, navigate)
    }

    return (
        <Card className="mt-10 p-4">
            <ToastContainer/>
            <form onSubmit={submitForm}>
                <CardHeader className="text-xl font-medium">
                    {null !== editReport
                        ? "Edit Meeting Report : " + editReport?.meetingId
                        : " New Meeting Report"
                    }
                </CardHeader>
                <CardBody className="flex flex-col gap-4">
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 items-center">
                        <Input
                            isRequired
                            type="text"
                            variant="underlined"
                            label="Venue"
                            className="max-w-md"
                            value={venue}
                            onValueChange={setVenue}
                        />
                        <Select
                            isRequired
                            items={DD_MEETING_TYPE}
                            label="Meeting Type"
                            variant="underlined"
                            className="max-w-md"
                            selectedKeys={meetingType}
                            onSelectionChange={setMeetingType}>
                            {meetingTypesListItems =>
                                <SelectItem key={meetingTypesListItems.value} value={meetingTypesListItems.value}>
                                    {meetingTypesListItems.label}
                                </SelectItem>
                            }
                        </Select>
                        <DatePicker
                            label="Start Date & Time"
                            description={"Date & Time Format : mm/dd/yyyy - hh:mm."}
                            className="max-w-md"
                            variant="underlined"
                            hideTimeZone
                            value={startTime}
                            onChange={setStartTime}
                            maxValue={now(getLocalTimeZone())}
                            showMonthAndYearPickers
                        />
                        <DatePicker
                            label="End Date & Time"
                            description={"Date & Time Format : mm/dd/yyyy - hh:mm."}
                            className="max-w-md"
                            variant="underlined"
                            hideTimeZone
                            value={endTime}
                            onChange={setEndTime}
                            maxValue={now(getLocalTimeZone())}
                            showMonthAndYearPickers
                        />
                    </div>
                    <Checkbox className="mt-4" isSelected={isDraftReport} onValueChange={setIsDraftReport}>
                        Save report as Draft?
                    </Checkbox>
                    {applicationRTESection(minutesOfMeeting,
                        handleUpdateForMoM,
                        null,
                        "Minutes of Meeting",
                        true,
                        null)}
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 items-center">
                        <h2 className="mt-10 text-lg text-gray-900 col-span-full">&nbsp;
                            {meetingType.keys().next().value === "Joint Meeting" ? "Home Club Meeting Finance" : "Meeting Finance"}</h2>
                        <Input
                            isRequired
                            type="number"
                            variant="underlined"
                            label="Income"
                            value={income}
                            onValueChange={setIncome}
                            placeholder="0"
                            className="max-w-md"
                        />
                        <Input
                            isRequired
                            type="number"
                            variant="underlined"
                            label="Expense"
                            value={expense}
                            onValueChange={setExpense}
                            placeholder="0"
                            className="max-w-md"
                        />
                        <Input
                            isDisabled
                            type="number"
                            variant="underlined"
                            label="Profit"
                            value={Number(income) >= Number(expense) ? Number(income) - Number(expense) : 0}
                            placeholder="0"
                            className="max-w-md"
                        />
                        <Input
                            isDisabled
                            type="number"
                            variant="underlined"
                            label="Loss"
                            value={Number(income) < Number(expense) ? Number(expense) - Number(income) : 0}
                            placeholder="0"
                            className="max-w-md"
                        />
                    </div>
                    {meetingType.keys().next().value === "Joint Meeting" &&
                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 items-center">
                            <h2 className="mt-10 text-lg text-gray-900 col-span-full">&nbsp;Overall Joint Meeting
                                Finance</h2>
                            <Input
                                isRequired
                                type="number"
                                variant="underlined"
                                label="Total Income"
                                value={jointEventIncome}
                                onValueChange={setJointEventIncome}
                                placeholder="0"
                                className="max-w-md"
                            />
                            <Input
                                isRequired
                                type="number"
                                variant="underlined"
                                label="Total Expense"
                                value={jointEventExpense}
                                onValueChange={setJointEventExpense}
                                placeholder="0"
                                className="max-w-md"
                            />
                            <Input
                                isDisabled
                                type="number"
                                variant="underlined"
                                label="Total Profit"
                                value={Number(jointEventIncome) >= Number(jointEventExpense) ? Number(jointEventIncome) - Number(jointEventExpense) : 0}
                                placeholder="0"
                                className="max-w-md"
                            />
                            <Input
                                isDisabled
                                type="number"
                                variant="underlined"
                                label="Total Loss"
                                value={Number(jointEventIncome) < Number(jointEventExpense) ? Number(jointEventIncome) - Number(jointEventExpense) : 0}
                                placeholder="0"
                                className="max-w-md"
                            />
                        </div>
                    }
                    <h2 className="mt-10 text-lg text-gray-900 col-span-full">&nbsp;Attendance</h2>
                    <Select
                        isRequired
                        selectionMode="multiple"
                        items={activeMembersList}
                        isLoading={membershipStatus === "pending"}
                        isDisabled={activeMembersList.length === 0}
                        label="Active Home Club Members"
                        variant="underlined"
                        className="col-span-2 sm:col-span-4"
                        selectedKeys={[...activeAttendees]}
                        onSelectionChange={setActiveAttendees}>
                        {members => <SelectItem key={members["membershipId"]} value={members["membershipId"]}>
                            {members.memberName}
                        </SelectItem>}
                    </Select>
                    <Select
                        selectionMode="multiple"
                        items={guestMembersList}
                        isLoading={membershipStatus === "pending"}
                        isDisabled={guestMembersList.length === 0}
                        label="Guest Home Club Members"
                        variant="underlined"
                        className="col-span-2 sm:col-span-4"
                        selectedKeys={guestAttendees}
                        onSelectionChange={setGuestAttendees}>
                        {members => <SelectItem key={members["membershipId"]} value={members["membershipId"]}>
                            {members.memberName}
                        </SelectItem>}
                    </Select>
                    <Select
                        selectionMode="multiple"
                        items={dcmDataList}
                        isLoading={dcmDataStatus === "pending"}
                        isDisabled={dcmDataList.length === 0}
                        label="District Council Members"
                        variant="underlined"
                        className="col-span-2 sm:col-span-4"
                        selectedKeys={dcmAttendees}
                        onSelectionChange={setDcmAttendees}>
                        {members => <SelectItem key={members["membershipId"]} value={members["membershipId"]}>
                            {members.memberName}
                        </SelectItem>}
                    </Select>
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 items-center">
                        <Input
                            isRequired
                            type="number"
                            variant="underlined"
                            label="Rotarians"
                            value={rotariansCount}
                            onValueChange={setRotariansCount}
                            placeholder="0"
                            className="max-w-md"
                        />
                        <Input
                            isRequired
                            type="number"
                            variant="underlined"
                            label="Alumnus"
                            value={alumnusCount}
                            onValueChange={setAlumnusCount}
                            placeholder="0"
                            className="max-w-md"
                        />
                        <Input
                            isRequired
                            type="number"
                            variant="underlined"
                            label="Interactors"
                            value={interactorsCount}
                            onValueChange={setInteractorsCount}
                            placeholder="0"
                            className="max-w-md"
                        />
                        <Input
                            isRequired
                            type="number"
                            variant="underlined"
                            label="Other Guests"
                            value={otherGuestsCount}
                            onValueChange={setOtherGuestsCount}
                            placeholder="0"
                            className="max-w-md"
                        />
                        <Input
                            isRequired
                            type="number"
                            variant="underlined"
                            label="Other Club Members"
                            value={otherClubMembersCount}
                            onValueChange={setOtherClubMembersCount}
                            placeholder="0"
                            className="max-w-md"
                        />
                        <Input
                            isRequired
                            type="number"
                            variant="underlined"
                            label="Other PIS"
                            value={otherPisCount}
                            onValueChange={setOtherPisCount}
                            placeholder="0"
                            className="max-w-md"
                        />
                        <Input
                            isRequired
                            type="number"
                            variant="underlined"
                            label="Other District Rotaractors"
                            value={otherDistrictRotaractorsCount}
                            onValueChange={setOtherDistrictRotaractorsCount}
                            placeholder="0"
                            className="max-w-md"
                        />
                        <Input
                            isDisabled
                            type="number"
                            variant="underlined"
                            label="Total"
                            value={activeAttendees.size + guestAttendees.size + dcmAttendees.size
                                + Number(rotariansCount) + Number(alumnusCount) + Number(interactorsCount) + Number(otherGuestsCount) + Number(otherClubMembersCount)
                                + Number(otherDistrictRotaractorsCount) + Number(otherPisCount)}
                            placeholder="0"
                            className="max-w-md"
                        />
                    </div>

                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 items-center">
                        <h2 className="mt-10 text-lg text-gray-900 col-span-full">&nbsp;Supporting Documents</h2>
                        <div className="grid grid-cols-1 gap-4 items-center">
                            <Input
                                isRequired
                                type="text"
                                variant="underlined"
                                label="Cover Image URL"
                                className="max-w-md"
                                isInvalid={isInvalidCoverImage}
                                color={isInvalidCoverImage ? "danger" : "success"}
                                errorMessage={isInvalidCoverImage && "Url is not of type 'https://drive.google.com/file/d/'. Please enter correct url"}
                                value={coverImageURL}
                                onValueChange={setCoverImageURL}/>
                            <Input
                                isRequired
                                type="text"
                                variant="underlined"
                                label="Support Documents URL"
                                className="max-w-md"
                                value={supportDocumentsURL}
                                onValueChange={setSupportDocumentsURL}/>
                        </div>
                        {coverImageURL && <div className="grid grid-cols-1 gap-4 items-center">
                            <img
                                src={getImageFromDrive(coverImageURL)}
                                alt="Cover Photo"
                                className="reportsCoverImage"/>
                        </div>}
                    </div>

                    {meetingType.keys().next().value === "Joint Meeting" &&
                        <>
                            <h2 className="mt-10 text-lg text-gray-900 col-span-full">&nbsp;Joint Meeting Clubs</h2>
                            <Select
                                isRequired
                                selectionMode="multiple"
                                items={clubsList}
                                isLoading={fetchClubListStatus === "pending"}
                                isDisabled={clubsList.length === 0}
                                label="Select Clubs"
                                variant="underlined"
                                className="col-span-2 sm:col-span-4"
                                selectedKeys={jointMeetingClubs}
                                onSelectionChange={setJointMeetingClubs}>
                                {clubs => <SelectItem key={clubs.clubCode} value={clubs.clubCode}>
                                    {clubs.name}
                                </SelectItem>}
                            </Select>
                        </>
                    }

                </CardBody>
                <CardFooter className="flex gap-4 justify-end">
                    <Button color="default" variant="bordered"
                            onClick={() => clearTaskAndCloseAmbassadorialScreen()}>
                        Cancel
                    </Button>

                    <Button color="success" variant="ghost" type="submit"
                            isLoading={newReportStatus === "pending" || editedReportStatus === "pending"}>
                        Submit
                    </Button>
                </CardFooter>
            </form>
        </Card>
    );
}