export const DD_AVENUES_LIST = [
    {label: "Joint Project", value: "Joint Project"},
    {label: "Community Service", value: "Community Service"},
    {label: "Club Service", value: "Club Service"},
    {label: "Career Development", value: "Career Development"},
    {label: "International Service", value: "International Service"},
    {label: "Sports", value: "Sports"},
    {label: "Digital Communications", value: "Digital Communications"},
    {label: "Partners In Service", value: "Partners In Service"},
    {label: "Training, Revival & Sustenance", value: "Training, Revival & Sustenance"},
    {label: "Editorial", value: "Editorial"},
    {label: "Public Relations", value: "Public Relations"},
];

export const DD_NOMINATION_FOR_LIST = [
    {label: "Best President", value: "Best President"},
    {label: "Best IPP", value: "Best IPP"},
    {label: "Best Vice President", value: "Best Vice President"},
    {label: "Best Team Secretariat", value: "Best Team Secretariat"},
    {label: "Best Director - Finance", value: "Best Director - Finance"},
    {label: "Best SAA", value: "Best SAA"},
    {label: "Best Director - Club Service", value: "Best Director - Club Service"},
    {label: "Best Director - Community Service", value: "Best Director - Community Service"},
    {label: "Best Director - Professional Development", value: "Best Director - Professional Development"},
    {label: "Best Director - Entrepreneurship Development", value: "Best Director - Entrepreneurship Development"},
    {label: "Best Director - International Service", value: "Best Director - International Service"},
    {label: "Best Director - Sports", value: "Best Director - Sports"},
    {label: "Best Director - Public Relations", value: "Best Director - Public Relations"},
    {label: "Best Director - Marketing", value: "Best Director - Marketing"},
    {label: "Best Director - Social Media/Digital Communications", value: "Best Director - Social Media"},
    {label: "Best Director - Partners-in-Service", value: "Best Director - Partners-in-Service"},
    {
        label: "Best Director - Training, Revival, and Sustenance",
        value: "Best Director - Training, Revival, and Sustenance"
    },
    {label: "Best Director - Creatives", value: "Best Director - Creatives"},
    {label: "Best Editor", value: "Best Editor"},
    {label: "Best Ambassador", value: "Best Ambassador"},
    {label: "Outstanding Newcomer", value: "Outstanding Newcomer"},
    {label: "Outstanding Rotaractor", value: "Outstanding Rotaractor"}
];

export const DD_CLUB_TYPE = [
    {label: "Community", value: "Community"},
    {label: "Institute", value: "Institute"}
];

export const DD_THRUST_AREA = [
    {label: "Environment", value: "Environment"},
    {label: "Empowerment", value: "Empowerment"},
    {label: "Membership", value: "Membership"},
    {label: "Education", value: "Education"},
    {label: "Branding", value: "Branding"},
    {label: "Mental Health", value: "Mental Health"},
    {label: "None of the above", value: "None of the above"}
];

export const DD_PROJECT_MODE = [
    {label: "Online", value: "Online"},
    {label: "On-ground", value: "On-ground"}
];

export const DD_NOMINATION_TYPES = [
    {key: "Quarter 1", value: "Quarter 1"},
    {key: "Quarter 2", value: "Quarter 2"},
    {key: "Quarter 3", value: "Quarter 3"},
    {key: "Quarter 4", value: "Quarter 4"},
    {key: "On Going", value: "On Going"},
    {key: "Flagship", value: "Flagship"}
];

export const DD_DESIGNATIONS = [
    {label: "President", value: "President"},
    {label: "Secretary", value: "Secretary"},
    {label: "Vice-President", value: "Vice-President"},
    {label: "Jt. Secretary", value: "Jt. Secretary"},
    {label: "Immediate Past President", value: "Immediate Past President"},
    {label: "Sergeant-At-Arms", value: "Sergeant-At-Arms"},
    {label: "Finance Chair", value: "Finance Chair"},
    {label: "Training, Revival and Sustenance Chair", value: "Training, Revival and Sustenance Chair"},
    {label: "Digital Communications Chair", value: "Digital Communications Chair"},
    {label: "Director - Club Service", value: "Director - Club Service"},
    {label: "Director - Community Service", value: "Director - Community Service"},
    {label: "Director - Digital Communications", value: "Director - Digital Communications"},
    {label: "Director - Sports", value: "Director - Sports"},
    {label: "Director - International Service", value: "Director - International Service"},
    {label: "Director - Career Development", value: "Director - Career Development"},
    {label: "Club Editor", value: "Club Editor"},
    {label: "Director - PR & Marketing", value: "Director - PR & Marketing"},
    {label: "Director - Partners-In-Service", value: "Director - Partners-In-Service"},
    {label: "General Body Member", value: "General Body Member"},
];

export const DD_PRANALI_ACCESS_FOR_PRES_SEC = [
    {label: "None", value: "None"},
    {label: "Limited", value: "Limited"},
    {label: "Full", value: "Bronze"},
];

export const DD_MEMBERSHIP_STATUS = [
    {label: "Active", value: "Active"},
    {label: "Guest", value: "Guest"},
    {label: "Alumni", value: "Alumni"},
    {label: "Left", value: "Left"},
    {label: "Terminated", value: "Terminated"},
];

export const DD_MEMBERSHIP_STATUS_NEW_MEMBERS = [
    {label: "Active", value: "Active"},
    {label: "Guest", value: "Guest"}
];

export const DD_BLOOD_GROUP = [
    {label: "A +ve", value: "A +ve"},
    {label: "B +ve", value: "B +ve"},
    {label: "AB +ve", value: "AB +ve"},
    {label: "O +ve", value: "O +ve"},
    {label: "A -ve", value: "A -ve"},
    {label: "B -ve", value: "B -ve"},
    {label: "AB -ve", value: "AB -ve"},
    {label: "O -ve", value: "O -ve"},
];

export const DD_GENDER = [
    {label: "Female", value: "Female"},
    {label: "Male", value: "Male"},
    {label: "Other", value: "Other"}
];

export const DD_TERMINATION_REASON = [
    {
        label: "For further studies or professional commitments",
        value: "For further studies or professional commitments"
    },
    {label: "Health reasons", value: "Health reasons"},
    {label: "Personal and family reasons", value: "Personal and family reasons"},
    {label: "In-activeness", value: "In-activeness"},
    {label: "Transfer to other club", value: "Transfer to other club"},
    {label: "Violation of code of conduct", value: "Violation of code of conduct"},
    {label: "Failed to adhere to club rules and regulations", value: "Failed to adhere to club rules and regulations"},
];

export const DD_MEETING_TYPE = [
    {label: "GBM", value: "GBM"},
    {label: "BOD", value: "BOD"},
    {label: "Joint Meeting", value: "Joint Meeting"},
    {label: "PIS", value: "PIS"},
    {label: "OCV", value: "OCV"},
    // {label: "Letterhead Exchange", value: "Letterhead Exchange"},
    {label: "Any Other", value: "Any Other"}
];

export const DD_PAYMENT_STATUS = [
    {label: "Successful", value: "Successful"},
    {label: "Refunded", value: "Refunded"}
];

export const DD_SOCIAL_MEDIA_PLATFORMS = [
    {label: "Instagram", value: "Instagram"},
    {label: "Facebook", value: "Facebook"},
    {label: "Twitter", value: "Twitter"},
    {label: "LinkedIn", value: "LinkedIn"},
    {label: "Blog", value: "Blog"}
];

export const DD_AMBASSADORIAL_TYPE = [
    {label: "Project", value: "Project"},
    {label: "Meeting", value: "Meeting"},
    {label: "PIS", value: "PIS"},
    {label: "Letter Head", value: "Letter Head"}
];