import {toast, ToastContainer} from "react-toastify";
import {
    Accordion,
    AccordionItem,
    Button,
    Card,
    CardBody,
    CardFooter,
    Checkbox,
    Input,
    Select,
    SelectItem,
} from "@nextui-org/react";

import React, {useState} from "react";
import {MailIcon} from "../Icons/Council57";
import {preferenceOptions} from "./preferenceOptions";
import {applicationRTESection} from "./ApplicationRTESection";
import {toastOptions} from "../../constants/WebPageConstants";
import axios from "axios";
import ApiEndpoint from "../../services/ApiEndpoint";
import {throwErrorToast} from "../admin/aaras/NewNomination";
import {Check} from "../Icons/SystemIcons";
import {useNavigate} from "react-router-dom";

export default function Questionnaire() {
    const navigate = useNavigate();
    const apiEndPoint = new ApiEndpoint();
    const [isSubmitting, setIsSubmitting] = React.useState(false);

    const [applicantName, setApplicantName] = React.useState(null);
    const [emailAddress, setEmailAddress] = React.useState(null);
    const [dateOfBirth, setDateOfBirth] = React.useState(null);
    const [contactNumber, setContactNumber] = React.useState(null);

    const [addressPart1, setAddressPart1] = React.useState(null);
    const [street, setStreet] = React.useState(null);
    const [landmark, setLandmark] = React.useState(null);
    const [city, setCity] = React.useState(null);
    const [zipcode, setZipcode] = React.useState(null);

    const [educationalQualification, setEducationalQualification] = React.useState(null);
    const [occupation, setOccupation] = React.useState(null);
    const [employer, setEmployer] = React.useState(null);

    const [yearOfJoining, setYearOfJoining] = React.useState(null);
    const [homeClub, setHomeClub] = React.useState(null);
    const [zone, setZone] = React.useState(null);
    const [pranaliId, setPranaliId] = React.useState(null);

    const [isOpenToOtherPositions, setIsOpenToOtherPositions] = useState(false);

    const [preference1, setPreference1] = useState(new Set([]));
    const [preference2, setPreference2] = useState(new Set([]));
    const [preference3, setPreference3] = useState(new Set([]));

    const [positionsTillDate, setPositionsTillDate] = useState(null);
    const [significantContribution, setSignificantContribution] = useState(null);
    const [achievements, setAchievements] = useState(null);
    const [preferenceReasons, setPreferenceReasons] = useState(null);
    const [whyCouncil57, setWhyCouncil57] = useState(null);
    const [strengths, setStrengths] = useState(null);
    const [traits, setTraits] = useState(null);
    const [commitmentsMidYear, setCommitmentsMidYear] = useState(null);
    const [seeUrSelfIn2Years, setSeeUrSelfIn2Years] = useState(null);
    const [suggestionsForEvents, setSuggestionsForEvents] = useState(null);

    const handleUpdateForPositionsTillDate = (value, editor) => {
        const length = editor.plugins["wordcount"].getCount();
        if (length <= 200) {
            setPositionsTillDate(value);
        } else {
            alert('Pasting this exceeds the maximum allowed number of 200 words');
            setPositionsTillDate('<p>This field can only take 200 words. Please refrain from adding more</p>');
        }
    };
    const handleUpdateForSignificantContribution = (value, editor) => {
        const length = editor.plugins["wordcount"].getCount();
        if (length <= 250) {
            setSignificantContribution(value);
        } else {
            alert('Pasting this exceeds the maximum allowed number of 250 words');
            setSignificantContribution('<p>This field can only take 250 words. Please refrain from adding more</p>');
        }
    };
    const handleUpdateForAchievements = (value, editor) => {
        const length = editor.plugins["wordcount"].getCount();
        if (length <= 250) {
            setAchievements(value);
        } else {
            alert('Pasting this exceeds the maximum allowed number of 250 words');
            setAchievements('<p>This field can only take 250 words. Please refrain from adding more</p>');
        }
    };
    const handleUpdateForPreferenceReasons = (value, editor) => {
        const length = editor.plugins["wordcount"].getCount();
        if (length <= 300) {
            setPreferenceReasons(value);
        } else {
            alert('Pasting this exceeds the maximum allowed number of 300 words');
            setPreferenceReasons('<p>This field can only take 300 words. Please refrain from adding more</p>');
        }
    };
    const handleUpdateForWhyCouncil57 = (value, editor) => {
        const length = editor.plugins["wordcount"].getCount();
        if (length <= 250) {
            setWhyCouncil57(value);
        } else {
            alert('Pasting this exceeds the maximum allowed number of 250 words');
            setWhyCouncil57('<p>This field can only take 250 words. Please refrain from adding more</p>');
        }
    };
    const handleUpdateForStrengths = (value, editor) => {
        const length = editor.plugins["wordcount"].getCount();
        if (length <= 200) {
            setStrengths(value);
        } else {
            alert('Pasting this exceeds the maximum allowed number of 200 words');
            setStrengths('<p>This field can only take 200 words. Please refrain from adding more</p>');
        }
    };
    const handleUpdateForTraits = (value, editor) => {
        const length = editor.plugins["wordcount"].getCount();
        if (length <= 250) {
            setTraits(value);
        } else {
            alert('Pasting this exceeds the maximum allowed number of 250 words');
            setTraits('<p>This field can only take 250 words. Please refrain from adding more</p>');
        }
    };
    const handleUpdateForCommitmentsMidYear = (value, editor) => {
        const length = editor.plugins["wordcount"].getCount();
        if (length <= 150) {
            setCommitmentsMidYear(value);
        } else {
            alert('Pasting this exceeds the maximum allowed number of 150 words');
            setCommitmentsMidYear('<p>This field can only take 150 words. Please refrain from adding more</p>');
        }
    };
    const handleUpdateForSeeUrSelfIn2Years = (value, editor) => {
        const length = editor.plugins["wordcount"].getCount();
        if (length <= 250) {
            setSeeUrSelfIn2Years(value);
        } else {
            alert('Pasting this exceeds the maximum allowed number of 250 words');
            setSeeUrSelfIn2Years('<p>This field can only take 250 words. Please refrain from adding more</p>');
        }
    };
    const handleUpdateForSuggestionsForEvents = (value, editor) => {
        const length = editor.plugins["wordcount"].getCount();
        if (length <= 250) {
            setSuggestionsForEvents(value);
        } else {
            alert('Pasting this exceeds the maximum allowed number of 250 words');
            setSuggestionsForEvents('<p>This field can only take 250 words. Please refrain from adding more</p>');
        }
    };

    const handleBeforeAddUndoFor150 = (evt, editor) => {
        const length = editor.plugins.wordcount.getCount();
        if (length > 150) {
            evt.preventDefault();
        }
    };
    const handleBeforeAddUndoFor200 = (evt, editor) => {
        const length = editor.plugins.wordcount.getCount();
        if (length > 200) {
            evt.preventDefault();
        }
    };
    const handleBeforeAddUndoFor250 = (evt, editor) => {
        const length = editor.plugins.wordcount.getCount();
        if (length > 250) {
            evt.preventDefault();
        }
    };
    const handleBeforeAddUndoFor300 = (evt, editor) => {
        const length = editor.plugins.wordcount.getCount();
        if (length > 300) {
            evt.preventDefault();
        }
    };

    const validateTextNotEmpty = (value) => value.toString().trim() !== "" && value.toString().trim().length > 3;
    const isTextInvalid = React.useMemo(() => {
        if (null === applicantName) return false;
        return !validateTextNotEmpty(applicantName);
    }, [applicantName]);

    const validateNumber = (value) => value.match(/^[0-9]+$/);
    const isNumberInvalid = React.useMemo(() => {
        if (null === contactNumber) return false;
        return !validateNumber(contactNumber);
    }, [contactNumber]);

    const validateContactNumber = (value) => value.match(/^[0-9]+$/) && value.toString().length === 10;
    const isContactInvalid = React.useMemo(() => {
        if (null === contactNumber) return false;
        return !validateContactNumber(contactNumber);
    }, [contactNumber]);

    function getToast(field) {
        return toast.error(<>
            <p className="text-medium">Mandatory Field Criteria Not Matched</p>
            <p className="text-small">{field} found blank or too short</p>
        </>, toastOptions)
    }

    function basicDetailsCheck() {
        return (null != applicantName
            && null != emailAddress
            && null != dateOfBirth
            && null != contactNumber);
    }

    function addressCheck() {
        return (null != addressPart1
            && null != street
            && null != landmark
            && null != city
            && null != zipcode);
    }

    function professionDetails() {
        return (null != educationalQualification
            && null != occupation
            && null != employer);
    }

    function rotaractDetails() {
        return (null != yearOfJoining
            && null != homeClub
            && null != zone
            && null != pranaliId);
    }

    function keepSubmitDisabled() {
        return !(basicDetailsCheck()
            && addressCheck()
            && professionDetails()
            && rotaractDetails()
            && null != preference1
            && null != preference2
            && null != preference3)
    }

    async function submitForm(e) {
        e.preventDefault();

        if (null === positionsTillDate || positionsTillDate.toString().trim().length < 5)
            getToast("Positions held till date")
        else if (null === significantContribution || significantContribution.toString().trim().length < 5)
            getToast("Significant contribution to the movement")
        else if (null === achievements || achievements.toString().trim().length < 5)
            getToast("Achievement in club or district")
        else if (null === preferenceReasons || preferenceReasons.toString().trim().length < 5)
            getToast("Achievement in club or district")
        else if (null === whyCouncil57 || whyCouncil57.toString().trim().length < 5)
            getToast("Why Council 57")
        else if (null === strengths || strengths.toString().trim().length < 5)
            getToast("Personal Strengths")
        else if (null === traits || traits.toString().trim().length < 5)
            getToast("Personal Traits ")
        else if (null === commitmentsMidYear || commitmentsMidYear.toString().trim().length < 5)
            getToast("Any commitments mid year")
        else if (null === seeUrSelfIn2Years || seeUrSelfIn2Years.toString().trim().length < 5)
            getToast("Self Vision in 2 years")
        else if (null === suggestionsForEvents || suggestionsForEvents.toString().trim().length < 5)
            getToast("Suggestions for District Events/Functions")
        else {
            setIsSubmitting(true);

            let pref = [];
            pref.push(preference1.keys().next().value);
            pref.push(preference2.keys().next().value);
            pref.push(preference3.keys().next().value);

            let applicationForm = {
                basicDetails: {
                    name: applicantName,
                    email: emailAddress,
                    contact: contactNumber,
                    dateOfBirth: dateOfBirth
                },
                address: {
                    addressLine1: addressPart1,
                    street: street,
                    landmark: landmark,
                    city: city,
                    zipcode: zipcode
                },
                professionDetails: {
                    educationalQualification: educationalQualification,
                    occupation: occupation,
                    workPlace: employer
                },
                rotaractDetails: {
                    yearOfJoining: yearOfJoining,
                    homeClub: homeClub,
                    zone: zone,
                    pranaliId: pranaliId
                },
                positionsTillDate: positionsTillDate,
                significantContribution: significantContribution,
                achievements: achievements,
                preferences: pref,
                preferenceReasons: preferenceReasons,
                isOpenToOtherPositions: isOpenToOtherPositions,
                whyCouncil57: whyCouncil57,
                strengths: strengths,
                traits: traits,
                commitmentsMidYear: commitmentsMidYear,
                seeUrSelfIn2Years: seeUrSelfIn2Years,
                suggestionsForEvents: suggestionsForEvents
            }
            await reportNewApplication(applicationForm).then(re => {
                    if (re.status === 201) {
                        toast.success(<>
                            <p className="text-medium">{"Application Submitted Successfully"}</p>
                            <p className="text-small">{"Application id allotted is : " + re.data}</p>
                        </>, toastOptions)
                        setIsSubmitting(false);
                        navigate("/thankyou");
                    }
                }
            );
        }
    }

    function reportNewApplication(applicationData) {
        return axios(apiEndPoint.submitCouncilApplication(applicationData))
            .then(res => res)
            .catch(e => {
                setIsSubmitting(false);
                throwErrorToast(e);
            });
    }

    return (
        <>
            <ToastContainer/>
            <Card>
                <form onSubmit={submitForm}>
                    <CardBody>
                        <h2 className="text-base font-semibold leading-7 text-gray-900">New Council#57 Application
                            Form</h2>

                        <Accordion selectionMode="multiple" className="mt-10 border-b border-gray-900/10">
                            <AccordionItem key="1" aria-label="Basic Details" title="Basic Details"
                                           startContent={<Check fill="currentColor" size={28}
                                                                className={`${basicDetailsCheck()
                                                                    ? "text-success" : "text-default"}`}
                                           />}>
                                <div className="mb-7 w-full flex flex-col gap-4">
                                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-12 items-center">
                                        <Input isRequired isClearable type="text" isInvalid={isTextInvalid}
                                            // color={applicantName === null ? null : isTextInvalid ? "danger" : "success"}
                                               onValueChange={setApplicantName}
                                               value={applicantName}
                                               variant="underlined" label="Name"
                                               errorMessage={isTextInvalid && "Please enter a valid name"}
                                               startContent={
                                                   <div className="pointer-events-none flex items-center">
                                                       <span className="text-default-400 text-small">Rtr.</span>
                                                   </div>
                                               }
                                        />
                                        <Input isRequired isClearable type="email"
                                               onValueChange={setEmailAddress}
                                               value={emailAddress}
                                               variant="underlined" label="Email"
                                               startContent={
                                                   <MailIcon
                                                       className="text-2xl text-default-400 pointer-events-none flex-shrink-0"/>
                                               }
                                        />
                                        <Input isRequired isClearable type="number"
                                               isInvalid={isContactInvalid}
                                               onValueChange={setContactNumber}
                                               value={contactNumber}
                                            // color={contactNumber === null ? null : isContactInvalid ? "danger" : "success"}
                                               variant="underlined" label="Contact Number"
                                               errorMessage={isContactInvalid && "Please enter a valid 10digit number"}
                                        />
                                        <Input isRequired name="startDate" type="date" variant="underlined"
                                               label="Date of Birth" placeholder="Enter Date"
                                               onValueChange={setDateOfBirth}
                                               value={dateOfBirth}/>
                                    </div>
                                </div>
                            </AccordionItem>
                            <AccordionItem key="2" aria-label="Address" title="Address"
                                           startContent={<Check fill="currentColor" size={28}
                                                                className={`${addressCheck()
                                                                    ? "text-success" : "text-default"}`}
                                           />}>
                                <div className="mb-7 w-full flex flex-col gap-4">
                                    <div className="grid grid-cols-2 sm:grid-cols-4 gap-12 items-center">
                                        <Input isRequired isClearable type="text" onValueChange={setAddressPart1}
                                               className="col-span-2 sm:col-span-3"
                                               variant="underlined"
                                               label="Flat/House no. + Floor + Complex/Apartment Name"/>
                                        <Input isRequired isClearable type="text" onValueChange={setStreet}
                                               variant="underlined" label="Street"/>
                                        <Input isRequired isClearable type="text" onValueChange={setLandmark}
                                               className="sm:col-span-2"
                                               variant="underlined" label="Landmark"/>
                                        <Input isRequired isClearable type="text" onValueChange={setCity}
                                               variant="underlined" label="City"/>
                                        <Input isRequired isClearable type="number" onValueChange={setZipcode}
                                               isInvalid={isNumberInvalid}
                                               errorMessage={isNumberInvalid && "Please postive and rounded digitis only"}
                                               variant="underlined" label="Zip code"/>
                                    </div>
                                </div>
                            </AccordionItem>
                            <AccordionItem key="3" aria-label="Profession Details" title="Profession Details"
                                           startContent={<Check fill="currentColor" size={28}
                                                                className={`${professionDetails()
                                                                    ? "text-success" : "text-default"}`}
                                           />}>
                                <div className="mb-7 w-full flex flex-col gap-4">
                                    <div className="grid grid-cols-1 sm:grid-cols-3 gap-12 items-center">
                                        <Input isRequired isClearable type="text"
                                               onValueChange={setEducationalQualification}
                                               variant="underlined" label="Educational Qualification"
                                        />
                                        <Input isRequired isClearable type="text" onValueChange={setOccupation}
                                               variant="underlined" label="Occupation as on 1st July 2024"
                                        />
                                        <Input isRequired isClearable type="text"
                                               variant="underlined" label="Employer/Business"
                                               onValueChange={setEmployer}/>
                                    </div>
                                </div>
                            </AccordionItem>
                            <AccordionItem key="4" aria-label="Rotaraction Details" title="Rotaraction Details"
                                           startContent={<Check fill="currentColor" size={28}
                                                                className={`${rotaractDetails()
                                                                    ? "text-success" : "text-default"}`}
                                           />}>
                                <div className="mb-7 w-full flex flex-col gap-4">
                                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-12 items-center">
                                        <Input isRequired isClearable type="month" onValueChange={setYearOfJoining}
                                               variant="underlined" label="Year of joining Rotaract"
                                               placeholder="Enter Date"
                                        />
                                        <Input isRequired isClearable type="text" onValueChange={setHomeClub}
                                               variant="underlined" label="Home Club"
                                        />
                                        <Input isRequired isClearable type="number" onValueChange={setZone}
                                               isInvalid={isNumberInvalid}
                                               variant="underlined" label="Zone"
                                        />
                                        <Input isRequired isClearable type="text" onValueChange={setPranaliId}
                                               variant="underlined" label="Pranali ID"/>
                                    </div>
                                </div>
                            </AccordionItem>
                        </Accordion>

                        {/*  POSITION HELD TILL DATE  */}
                        {applicationRTESection(positionsTillDate,
                            handleUpdateForPositionsTillDate,
                            handleBeforeAddUndoFor200,
                            "Positions held till date",
                            true,
                            "(please mention year-wise, max 200 words)")}

                        {/*  CONTRIBUTION TO MOVEMENT  */}
                        {applicationRTESection(significantContribution,
                            handleUpdateForSignificantContribution,
                            handleBeforeAddUndoFor250,
                            "Significant contribution to the movement",
                            true,
                            "(please mention year-wise at club, zone and district level, max 250 words)")}

                        {/*  Achievement in Club / District  */}
                        {applicationRTESection(achievements,
                            handleUpdateForAchievements,
                            handleBeforeAddUndoFor250,
                            "Any achievement in club or district",
                            true,
                            "(till date, max 250 words)")}

                        {/*  PREFERENCES */}
                        <div>
                            <h2 className="mt-10 text-lg text-gray-900">&nbsp;Preferences</h2>
                            <div className="grid grid-cols-1 sm:grid-cols-3 gap-12 items-center">
                                <Select isRequired
                                        label="Preference 1"
                                        variant="underlined"
                                        className="max-w-md"
                                        disableAnimation={false}
                                        selectedKeys={preference1}
                                        onSelectionChange={setPreference1}
                                >
                                    {preferenceOptions.map((avenues) => (
                                        <SelectItem key={avenues.uid} value={avenues.name}>
                                            {avenues.name}
                                        </SelectItem>
                                    ))}
                                </Select>

                                <Select isRequired
                                        label="Preference 2"
                                        variant="underlined"
                                        className="max-w-md"
                                        disableAnimation={false}
                                        selectedKeys={preference2}
                                        onSelectionChange={setPreference2}
                                >
                                    {preferenceOptions.map((avenues) => (
                                        <SelectItem key={avenues.uid} value={avenues.name}>
                                            {avenues.name}
                                        </SelectItem>
                                    ))}
                                </Select>

                                <Select isRequired
                                        label="Preference 3"
                                        variant="underlined"
                                        className="max-w-md"
                                        disableAnimation={false}
                                        selectedKeys={preference3}
                                        onSelectionChange={setPreference3}
                                >
                                    {preferenceOptions.map((avenues) => (
                                        <SelectItem key={avenues.uid} value={avenues.name}>
                                            {avenues.name}
                                        </SelectItem>
                                    ))}
                                </Select>
                            </div>
                        </div>

                        {/*  Preference Reasons  */}
                        {applicationRTESection(preferenceReasons,
                            handleUpdateForPreferenceReasons,
                            handleBeforeAddUndoFor300,
                            "Explain in brief Reason for applying for all three positions",
                            true,
                            "(max 300 words)")}


                        <Checkbox className="mt-4" isSelected={isOpenToOtherPositions}
                                  onValueChange={setIsOpenToOtherPositions}>
                            Will you be open to any Council position other than the above preferences?
                        </Checkbox>

                        {/*  WHY COUNCIL 57  */}
                        {applicationRTESection(whyCouncil57,
                            handleUpdateForWhyCouncil57,
                            handleBeforeAddUndoFor250,
                            "Why should you be selected in Council 57",
                            true,
                            "(max 250 words)")}

                        {/*  STRENGTHS  */}
                        {applicationRTESection(strengths,
                            handleUpdateForStrengths,
                            handleBeforeAddUndoFor200,
                            "Strengths",
                            true,
                            "(Including any academic/ professional experience that may enable you in performing your role as a Council Member, max 200 words)")}

                        {/*  TRAITS  */}
                        {applicationRTESection(traits,
                            handleUpdateForTraits,
                            handleBeforeAddUndoFor250,
                            "Traits you want to work upon during your tenure as council member",
                            true,
                            "(max 250 words)")}

                        {/*  COMMITMENTS  */}
                        {applicationRTESection(commitmentsMidYear,
                            handleUpdateForCommitmentsMidYear,
                            handleBeforeAddUndoFor150,
                            "Any intercity/overseas academic or professional commitments that could come up mid-year ?",
                            true,
                            "(R.I.Y 2024-25, max 150 words)")}

                        {/*  SEE YOURSELF IN 2 YEARS  */}
                        {applicationRTESection(seeUrSelfIn2Years,
                            handleUpdateForSeeUrSelfIn2Years,
                            handleBeforeAddUndoFor250,
                            "In your Rotaract journey, where do you see yourself 2 years down the line?",
                            true,
                            "(max 250 words)")}

                        {/*  EVENT SUGGESTIONS  */}
                        {applicationRTESection(suggestionsForEvents,
                            handleUpdateForSuggestionsForEvents,
                            handleBeforeAddUndoFor250,
                            "Any suggestions for the district functioning / district events?",
                            true,
                            "(max 250 words)")}

                    </CardBody>
                    <CardFooter className="flex flex-wrap gap-4 justify-end">
                        <Button type="submit"
                                isDisabled={keepSubmitDisabled()}
                                color="primary" variant="ghost"
                                isLoading={isSubmitting}
                        >
                            Submit
                        </Button>
                    </CardFooter>
                </form>
            </Card>
        </>
    );
}