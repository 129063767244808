import ApiEndpoint from "./ApiEndpoint";
import {
    setDistrictCouncilMembersData,
    setDistrictCouncilMembersDataStatus,
    setEditedMembershipDataStatus,
    setMembershipData,
    setMembershipDataStatus,
    setSearchedMembersData,
    setSearchedMembersDataStatus,
    setSelectedMemberData,
    setSelectedMemberDataStatus
} from "../components/redux/slice/membershipSlice";
import axios from "axios";
import {throwErrorToast} from "../components/admin/aaras/NewNomination";
import {toast} from "react-toastify";
import {toastOptions} from "../constants/WebPageConstants";
import React from "react";

export function getMembershipData(dispatch, user) {
    const apiEndPoint = new ApiEndpoint();
    dispatch(setMembershipDataStatus("pending"));
    dispatch(setMembershipData([]));

    return axios(apiEndPoint.getMembersList(user, false, null))
        .then(res => {
            if (res.status === 200) {
                dispatch(setMembershipData(res.data));
                dispatch(setMembershipDataStatus("complete"));
            }
            return res;
        })
        .catch(e => {
            dispatch(setMembershipDataStatus("complete"));
            throwErrorToast(e);
        });
}


export function getDistrictCouncilMembersData(dispatch, user) {
    const apiEndPoint = new ApiEndpoint();
    dispatch(setDistrictCouncilMembersDataStatus("pending"));
    dispatch(setDistrictCouncilMembersData([]));

    return axios(apiEndPoint.getMembersList(user, true, null))
        .then(res => {
            if (res.status === 200) {
                dispatch(setDistrictCouncilMembersData(res.data));
                dispatch(setDistrictCouncilMembersDataStatus("complete"));
            }
            return res;
        })
        .catch(e => {
            dispatch(setDistrictCouncilMembersDataStatus("complete"));
            throwErrorToast(e);
        });
}

export function getMembershipDataById(dispatch, user, membershipId) {
    const apiEndPoint = new ApiEndpoint();
    dispatch(setSelectedMemberData(null));
    dispatch(setSelectedMemberDataStatus("pending"));

    return axios(apiEndPoint.getMemberDataById(user, membershipId))
        .then(res => {
            if (res.status === 200) {
                dispatch(setSelectedMemberData(res.data));
                dispatch(setSelectedMemberDataStatus("complete"));
            }
            return res;
        })
        .catch(e => {
            dispatch(setSelectedMemberDataStatus("complete"));
            throwErrorToast(e);
        });
}

export function searchMember(dispatch, user, memberName, memberDOB) {
    const apiEndPoint = new ApiEndpoint();
    dispatch(setSearchedMembersDataStatus("pending"));


    let dob = new Date(memberDOB).toISOString().split("T")[0];

    return axios(apiEndPoint.searchMemberDataByBy(user, memberName, dob))
        .then(res => {
            if (res.status === 200) {
                dispatch(setSearchedMembersData(res.data));
                dispatch(setSearchedMembersDataStatus("complete"));
            }
            return res;
        })
        .catch((e) => {
            dispatch(setSearchedMembersDataStatus("complete"));
            throwErrorToast(e);
        });
}

export function confirmMemberRegistration(dispatch, user, memberData, navigate) {
    const apiEndPoint = new ApiEndpoint();
    dispatch(setEditedMembershipDataStatus("pending"));

    function closeMemberEdit() {
        dispatch(setSelectedMemberData(null));
        dispatch(setEditedMembershipDataStatus('idle'));
        navigate("..");
    }

    return axios(apiEndPoint.addMember(user, memberData.membershipId, memberData))
        .then(res => {
            if (res.status === 201) {
                dispatch(setEditedMembershipDataStatus("complete"));
                toast.success(<>
                    <p className="text-medium">Member added 🥳</p>
                    <p className="text-small">New member successfully added. Redirecting to main page...</p>
                </>, toastOptions);
                setTimeout(closeMemberEdit, 2500);
            }
            return res;
        })
        .catch((e) => {
            dispatch(setEditedMembershipDataStatus("complete"));
            throwErrorToast(e);
        });
}

export function editMemberData(dispatch, user, editedData, navigate) {
    const apiEndPoint = new ApiEndpoint();
    dispatch(setEditedMembershipDataStatus("pending"));

    function closeMemberEdit() {
        dispatch(setSelectedMemberData(null));
        dispatch(setEditedMembershipDataStatus('idle'));
        navigate("/pranali/membership");
    }

    return axios(apiEndPoint.editMember(user, editedData.membershipId, editedData))
        .then((res) => {
            if (res.status === 204) {
                dispatch(setEditedMembershipDataStatus("complete"));
                getMembershipData(dispatch, user);
                toast.success(<>
                    <p className="text-medium">Success</p>
                    <p className="text-small">Membership Data successfully updated. Redirecting to main page...</p>
                </>, toastOptions);
                setTimeout(closeMemberEdit, 2500);
            }
            return res;
        })
        .catch((e) => {
            dispatch(setEditedMembershipDataStatus("complete"));
            throwErrorToast(e);
        });
}