import {useSelector, useDispatch} from "react-redux";
import QuarterlyNominations from "./QuarterlyNominations";
import ApiEndpoint from "../../../services/ApiEndpoint";
import axios from "axios";
import {selectAllUserData} from "../../redux/slice/userSlice";
import {setAaras, setStatus, setStatusOfIN} from "../../redux/slice/aaraSlice";
import React, {useEffect} from "react";
import {toast, ToastContainer} from "react-toastify";
import {toastOptions} from "../../../constants/WebPageConstants";

export default function Nominations() {
    const user = useSelector(selectAllUserData);
    const dispatch = useDispatch();

    useEffect(() => {
        getAaraNominations(dispatch, user);
    }, []);

    return (<>
            <ToastContainer/>
            <QuarterlyNominations/>
        </>
    );

}

export function getAaraNominations(dispatch, user) {

    const apiEndPoint = new ApiEndpoint();
    dispatch(setStatus("pending"));

    return axios(apiEndPoint.getAaraNominations(user))
        .then((res) => {
            if (res.status === 200) {
                dispatch(setAaras(res.data));
                dispatch(setStatus("complete"));
            }
            return res;
        })
        .catch((e) => {
            dispatch(setStatus("complete"));
            toast.error(<>
                <p className="text-medium">{null != e?.response?.data?.code ? e.response.data.code.replaceAll("_", "") : "Internal Server Error"}</p>
                <p className="text-small">{null != e?.response?.data?.message ? e.response.data.message : "Please contact IT Team to report and resolve the error"}</p>
            </>, toastOptions);
        });
}

export function initiateIndividualNominations(dispatch, user,data) {

    const apiEndPoint = new ApiEndpoint();
    dispatch(setStatusOfIN("pending"));

    return axios(apiEndPoint.initiateIndividualAaraNominations(user,data))
        .then((res) => {
            if (res.status === 201) {
                dispatch(setStatusOfIN("complete"));
            }
            return res;
        })
        .catch((e) => {
            dispatch(setStatusOfIN("complete"));
            toast.error(<>
                <p className="text-medium">{null != e?.response?.data?.code ? e.response.data.code.replaceAll("_", "") : "Internal Server Error"}</p>
                <p className="text-small">{null != e?.response?.data?.message ? e.response.data.message : "Please contact IT Team to report and resolve the error"}</p>
            </>, toastOptions);
        });
}