import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import WalletCard from "./WalletCard";
import TransactionsTable from "./TransactionsTable";
import ApiEndpoint from "../../../services/ApiEndpoint";
import axios from "axios";
import {setFinanceRecordsStatus, setRecords} from "../../redux/slice/financeSlice";
import {selectAllUserData} from "../../redux/slice/userSlice";
import {throwErrorToast} from "../aaras/NewNomination";
import {ONLY_PRES_SEC_AND_IT} from "../../../constants/WebPageConstants";
import {Button} from "@nextui-org/react";
import {Refresh} from "../../Icons/SystemIcons";
import {getClubDataById} from "../dashboard/Dashboard";

export default function Finances() {
    const user = useSelector(selectAllUserData);
    const dispatch = useDispatch();

    useEffect(() => {
        getFinanceRecords(dispatch, user);
    }, []);

    function refreshWallet() {
        getFinanceRecords(dispatch, user);
        getClubDataById(dispatch, user);
    }


    return (
        <div className="grid grid-cols-1 justify-items-center gap-y-8">
            {ONLY_PRES_SEC_AND_IT.includes(user.pranaliAccess) && <WalletCard/>}

            {!ONLY_PRES_SEC_AND_IT.includes(user.pranaliAccess) &&
                <div className="flex justify-around gap-4 sm:gap-8 items-center">
                    <div className="relative">
                        <h1 className="text-2xl sm:text-3xl font-medium">Finances</h1>
                    </div>

                    <div className="flex flex-col">
                        <div className="flex justify-end items-center">
                            <Button type="button" isIconOnly color="default" variant="ghost"
                                    onClick={() => refreshWallet(dispatch, user, false)}>
                                <Refresh fill="currentColor" size={28}/>
                            </Button>
                        </div>
                    </div>
                </div>}

            <div className="container">
                <TransactionsTable/>
            </div>
        </div>
    );
}

export function getFinanceRecords(dispatch, user) {

    const apiEndPoint = new ApiEndpoint();
    dispatch(setFinanceRecordsStatus("pending"));

    axios(apiEndPoint.getFinanceRecords(user))
        .then((res) => {
            if (res.status === 200) {
                dispatch(setRecords(res.data.reverse()));
                dispatch(setFinanceRecordsStatus("complete"));
            }
            return res;
        })
        .catch((e) => {
            dispatch(setFinanceRecordsStatus("complete"));
            throwErrorToast(e);
        });
}