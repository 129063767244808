import {Button, Card, CardBody, CardFooter, Checkbox, Input, Progress, Select, SelectItem} from "@nextui-org/react";
import {NOMINATION_STATUS_DRAFT, NOMINATION_TYPE_YEAR_END, toastOptions} from "../../../constants/WebPageConstants";
import React, {useEffect, useState} from "react";
import {DD_AVENUES_LIST, DD_NOMINATION_TYPES} from "../../../constants/dropdownList";
import {Add, Minus} from "../../Icons/SystemIcons";
import ApiEndpoint from "../../../services/ApiEndpoint";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import {selectAllUserData} from "../../redux/slice/userSlice";
import {
    addAaraNominations,
    getIndexForEdit,
    selectDataForEdit, setDataForEdit, setIndexForEdit,
    updateAaraNominations
} from "../../redux/slice/aaraSlice";
import {toast, ToastContainer} from "react-toastify";
import {useNavigate} from "react-router-dom";

export default function NewNomination() {
    const user = useSelector(selectAllUserData);
    const editData = useSelector(selectDataForEdit);
    const indexForEdit = useSelector(getIndexForEdit);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const apiEndPoint = new ApiEndpoint();
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(false)
    const [isFetchingProjects, setIsFetchingProjects] = useState(false);
    const [fetchedProjects, setFetchedProjects] = useState([]);
    const [isSubmittingNomination, setIsSubmittingNomination] = useState(false);
    const [isDraftNomination, setIsDraftNomination] = useState(editData?.adminData?.status === NOMINATION_STATUS_DRAFT);
    const [nominationType, setNominationType] = useState(null != editData?.nominationType ? new Set([editData.nominationType]) : new Set([]));

    const [nominations, setNominations] = useState(null != editData?.projectNominations
        ? [...editData.projectNominations]
        : [{projectId: "", projectName: "", nominationFor: ""}]
    );

    const [quarterlyData, setQuarterlyData] = useState([]);

    const addFields = () => {
        setNominations([...nominations, {projectId: "", projectName: "", nominationFor: ""}])
    }

    const removeFormFields = (i) => {
        let updatedValues = nominations;
        updatedValues.splice(i, 1);
        setNominations([...updatedValues]);
    }

    const updateQuarterlyData = (e) => {
        setNominationType(new Set([e.target.value]));
        setNominations([{projectId: "", projectName: "", nominationFor: ""}])
        if (NOMINATION_TYPE_YEAR_END.includes(e.target.value))
            setQuarterlyData(fetchedProjects)
        else
            setQuarterlyData(fetchedProjects.filter(project => project.adminData.reportingQuarter === e.target.value));
    }

    const addProjectDetailsToNominations = (i, e) => {
        let newFormValues = [...nominations];
        newFormValues[i].projectId = e.target.value;
        newFormValues[i].projectName = quarterlyData.find(project => project.projectId === e.target.value).name;
        setNominations(newFormValues);
        if (!checkProjectUniqueness()) {
            toast.error(<>
                <p className="text-medium">{"No Same Nominations Allowed"}</p>
                <p className="text-small">{"2 or more nominations for the same project found"}</p>
            </>, toastOptions)
        }
    }

    const addNominationAvenue = (i, e) => {
        let newFormValues = [...nominations];
        newFormValues[i].nominationFor = e.target.value;
        setNominations(newFormValues);
    }

    async function submitForm(e) {
        e.preventDefault();
        setIsSubmittingNomination(!isSubmittingNomination);

        let aaraData = {
            nominationType: nominationType.keys().next().value,
            isDraftRequest: isDraftNomination,
            projectNominations: nominations
        }

        function cleanUpBeforeClose() {
            setIsSubmittingNomination(false);
            cancelForm();
        }

        if (null === indexForEdit) {
            await reportNewAara(aaraData).then(re => {
                    if (re.status === 201) {
                        toast.success(<>
                            <p className="text-medium">{aaraData.isDraftRequest ? "Nomination Saved Successfully" : "Nomination Submitted Successfully"}</p>
                            <p className="text-small">{"Nomination id allotted : " + re.data.nominationId + ". This page will be closing soon"}</p>
                        </>, toastOptions)
                        dispatch(addAaraNominations(re.data));
                        setTimeout(cleanUpBeforeClose, 3000);
                    }
                }
            );
        } else {
            aaraData["nominationId"] = editData.nominationId;
            await updateExistingAara(aaraData).then(re => {
                    if (re.status === 202) {
                        toast.success(<>
                            <p className="text-medium">{aaraData.isDraftRequest ? "Nomination " + aaraData.nominationId + " Updated Successfully" : "Nomination " + aaraData.nominationId + " Submitted Successfully"}</p>
                            <p className="text-small">{"This page will be closing soon"}</p>
                        </>, toastOptions)
                        dispatch(updateAaraNominations(re.data));
                        setTimeout(cleanUpBeforeClose, 3000);
                    }
                }
            );
        }
        // In Case of Error
        setIsSubmittingNomination(false);
    }

    function cancelForm() {
        dispatch(setIndexForEdit(null));
        dispatch(setDataForEdit(null))
        navigate("/pranali/aara")
        // window.opener = null;
        // window.open("", "_self");
        // window.close();
    }

    function reportNewAara(aaraData) {
        return axios(apiEndPoint.addAaraNominations(user, aaraData))
            .then(res => res)
            .catch(e => {
                setIsSubmittingNomination(false);
                throwErrorToast(e);
            });
    }

    function updateExistingAara(aaraData) {
        return axios(apiEndPoint.updateAaraNominations(user, aaraData))
            .then(res => res)
            .catch(e => {
                setIsSubmittingNomination(false);
                throwErrorToast(e);
            });
    }

    function getProjects() {
        setIsFetchingProjects(true);
        return axios(apiEndPoint.getProjectsForAARA(user)).then(res => {
            setIsFetchingProjects(false);
            setFetchedProjects(res.data);
            if (null != editData && null !== editData.projectNominations) {
                setQuarterlyData(res.data.filter(project => {
                    return project.adminData.reportingQuarter === editData.nominationType;
                }))
            }
            return res
        }).catch(e => {
            throwErrorToast(e);
            setIsFetchingProjects(false);
        });
    }

    const checkProjectUniqueness = () => {
        let IDs = new Set(nominations.map(item => item.projectId));
        let isSame = [...IDs].length === nominations.length
        isSame ? setIsSubmitDisabled(false) : setIsSubmitDisabled(true);
        return isSame;
    }

    useEffect(() => {
        getProjects()
    }, []);

    return (
        <>
            <ToastContainer/>
            <Card>
                <form onSubmit={submitForm}>
                    <CardBody>
                        {null !== indexForEdit
                            ?
                            <h2 className="text-base font-semibold leading-7 text-gray-900">Update AARA Nomination :
                                <span className="text-sm text-gray-500"> {editData.nominationId}</span></h2>
                            : <h2 className="text-base font-semibold leading-7 text-gray-900">New AARA Nomination</h2>}
                        <div className="mt-10 w-full flex flex-col gap-4">
                            <Select isRequired
                                    labelPlacement="outside-left"
                                    label="Nomination Type"
                                    variant="underlined"
                                    className="max-w-md"
                                    disableAnimation={false}
                                    selectedKeys={nominationType}
                                    onSelectionChange={setNominationType}
                                    onChange={updateQuarterlyData}
                            >
                                {DD_NOMINATION_TYPES.map((nominations) => (
                                    <SelectItem key={nominations.key} value={nominations.key}>
                                        {nominations.value}
                                    </SelectItem>
                                ))}
                            </Select>
                        </div>
                        <div className="mt-10 w-full flex flex-col gap-4">
                            <Checkbox isSelected={isDraftNomination} onValueChange={setIsDraftNomination}>
                                Save nomination as Draft
                            </Checkbox>
                        </div>
                        {isFetchingProjects &&
                            <div className="mt-10 w-full flex flex-col gap-4">
                                <Progress
                                    size="sm"
                                    isIndeterminate
                                    aria-label="Loading..."
                                />
                                <p className="text-sm font-regular leading-7 text-gray-900">Please wait while we fetch
                                    projects for You 😘</p>
                            </div>
                        }
                        {(quarterlyData && quarterlyData.length > 0) &&
                            <>
                                <div className="mt-10 w-full flex flex-col gap-4">
                                    <p className="text-sm font-regular leading-7 text-gray-900">Add project's that you
                                        want
                                        to
                                        nominate</p>
                                    {nominations.map((projectDetails, index) => (
                                        <div className="grid grid-cols-2 sm:grid-cols-12 gap-4 items-center">
                                            <Select
                                                isRequired
                                                label="Project Name"
                                                defaultSelectedKeys={[projectDetails.projectId]}
                                                variant="underlined"
                                                className="max-w-md col-span-2 sm:col-span-4 md:order-2"
                                                onChange={e => addProjectDetailsToNominations(index, e)}
                                            >
                                                {quarterlyData.map(data =>
                                                    <SelectItem key={data.projectId} value={data.name}>
                                                        {data.name}
                                                    </SelectItem>
                                                )}
                                            </Select>
                                            <Select
                                                isRequired
                                                label="Nomination For Avenue"
                                                variant="underlined"
                                                className="max-w-md col-span-2 sm:col-span-4 md:order-3"
                                                defaultSelectedKeys={[projectDetails.nominationFor]}
                                                onChange={e => addNominationAvenue(index, e)}
                                            >
                                                {projectDetails.projectId && DD_AVENUES_LIST.filter(avenues => {
                                                    let qd = quarterlyData.find(data => data.projectId === projectDetails.projectId);
                                                    if (avenues.value === "Joint Project" && qd.isJointProject) {
                                                        return true
                                                    } else {
                                                        return !qd.isJointProject && (avenues.value === qd.avenueMain || avenues.value === qd?.avenueOptional)
                                                    }
                                                }).map(data => (
                                                    <SelectItem key={data.value} value={data.value}>
                                                        {data.label}
                                                    </SelectItem>
                                                ))}
                                            </Select>
                                            <Input
                                                isDisabled
                                                type="text"
                                                variant="underlined"
                                                label="Project Id"
                                                value={projectDetails.projectId}
                                                className="max-w-md col-span-1 sm:col-span-3"
                                            />
                                            {nominations.length > 1
                                                ? <Button isIconOnlytype="button" color="danger" variant="flat"
                                                          className="w-auto col-span-1 sm:col-span-1 md:order-4"
                                                          onClick={() => removeFormFields(index)}>
                                                    <Minus fill="currentColor"
                                                           size={28}/>
                                                </Button>
                                                : null}
                                        </div>
                                    ))}
                                </div>
                                <div className="mt-10 w-full flex justify-center">
                                    <Button className="max-w-sm" color="primary" variant="bordered" startContent={
                                        <Add fill="currentColor" size={28}/>
                                    } onClick={addFields}>
                                        Add more
                                    </Button>
                                </div>
                            </>
                        }
                        {(!nominationType.has('') && quarterlyData.length === 0 && !isFetchingProjects) &&
                            <div className="mt-10 mb-10 w-full flex flex-col  text-center content-center">

                                <p className="text-lg font-regular text-gray-500">No Records found</p>
                                <p className="text-md font-regular font-semibold text-gray-600">For the given Nomination
                                    Type 🥲</p>
                            </div>}
                    </CardBody>
                    <CardFooter className="flex flex-wrap gap-4 justify-end">
                        <Button type="button" color="default" variant="faded" onClick={() => cancelForm()}>
                            Cancel
                        </Button>
                        <Button type="submit" isDisabled={quarterlyData.length === 0 || isSubmitDisabled}
                                color="primary" variant="ghost"
                                isLoading={isSubmittingNomination}>
                            Submit
                        </Button>
                    </CardFooter>
                </form>
            </Card>
        </>
    );
}

export function throwErrorToast(e) {
    toast.error(<>
        <p className="text-medium">{null != e?.response?.data?.code ? e.response.data.code.replaceAll("_", " ") : "Internal Server Error"}</p>
        <p className="text-small">{null != e?.response?.data?.message ? e.response.data.message : "Please contact IT Team to report and resolve the error"}</p>
    </>, toastOptions)
}