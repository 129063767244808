import React from "react";
import {Accordion, AccordionItem, Button, DatePicker, Input, Select, SelectItem} from "@nextui-org/react";
import {DD_CLUB_TYPE, DD_GENDER} from "../../../constants/dropdownList";

export default function OnBoardNewClubAndOfficers() {

    const [isOnBoardClubKey, setIsOnBoardClubKey] = React.useState(false);
    const [isOnBoardOfficersKey, setIsOnBoardOfficersKey] = React.useState(true);

    const [clubName, setClubName] = React.useState(null);
    const [zoneNumber, setZoneNumber] = React.useState(null);
    const [initialWalletBalance, setInitialWalletBalance] = React.useState(null);
    const [type, setType] = React.useState('');
    const [charterDate, setCharterDate] = React.useState();


    const defaultContent =
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.";


    return (
        <Accordion variant="splitted" defaultExpandedKeys={["2"]}>
            <AccordionItem isDisabled={isOnBoardClubKey} key="1" aria-label="On-Board New club"
                           title="On-Board New club">
                <div className="flex flex-row items-center gap-4 flex-wrap mb-4">
                    <Input isRequired
                           type="text"
                           variant="underlined"
                           value={clubName}
                           onValueChange={setClubName}
                           startContent={
                               <div className="pointer-events-none flex items-center">
                                   <span className="text-default-400 text-small">RC</span>
                               </div>
                           }
                           label="Club Name"
                           className="max-w-md"
                    />
                    <Input isRequired
                           type="number"
                           variant="underlined"
                           label="Zone Number"
                           value={zoneNumber}
                           onValueChange={setZoneNumber}
                        // isInvalid={isPrimaryContactInvalid}
                        // errorMessage={isPrimaryContactInvalid && "Please enter a valid 10digit number"}
                           className="max-w-md"
                    />
                    <DatePicker
                        isRequired
                        variant="underlined"
                        label="Charter Date"
                        showMonthAndYearPickers
                        value={charterDate}
                        onChange={setCharterDate}
                        className="max-w-md"
                    />
                    <Input isClearable
                           type="number"
                           variant="underlined"
                           label="Initial Wallet Balance"
                           value={initialWalletBalance}
                           onValueChange={setInitialWalletBalance}
                        // isInvalid={isSecondaryContactInvalid}
                        // errorMessage={isSecondaryContactInvalid && "Please enter a valid 10digit number"}
                           className="max-w-md"
                    />
                    <Select isRequired
                            label="Club Type"
                            defaultSelectedKeys={[type]}
                            variant="underlined"
                            className="max-w-md"
                            onChange={e => setType(e.target.value)}>
                        {DD_CLUB_TYPE.map(data =>
                            <SelectItem key={data.label} value={data.value}>
                                {data.label}
                            </SelectItem>
                        )}
                    </Select>
                    <Button color="success" variant="ghost"
                            className="place-self-end"
                            isDisabled={clubName === null && zoneNumber === null && charterDate === null && initialWalletBalance === null && type === null}
                        // onClick={() => goToNextPageWithSearchedData()}
                    >
                        Submit & On-Board Club
                    </Button>
                </div>
            </AccordionItem>
            <AccordionItem isDisabled={isOnBoardOfficersKey} key="2" aria-label="On-Board Club Officers"
                           title="On-Board Club Officers">
                {defaultContent}
            </AccordionItem>
        </Accordion>
    )
}