import {useDispatch, useSelector} from "react-redux";
import {selectAllUserData} from "../../redux/slice/userSlice";
import React, {useEffect} from "react";
import {getAaraNominations} from "./Nominations";
import ClubWiseNominationTypeView from "./ClubWiseNominationTypeView";
import {Accordion, AccordionItem} from "@nextui-org/react";
import ProjectNominationsView from "./ProjectNominationsView";

export default function DistrictAdminView() {
    const user = useSelector(selectAllUserData);
    const dispatch = useDispatch();

    useEffect(() => {
        getAaraNominations(dispatch, user);
    }, []);

    return (<>
        <Accordion variant="splitted" defaultExpandedKeys={["1"]}>
            <AccordionItem key="1" aria-label="Club Wise" title="Club Wise">
                <ClubWiseNominationTypeView/>
            </AccordionItem>
            <AccordionItem key="2" aria-label="Project Wise" title="Project Wise">
                <ProjectNominationsView/>
            </AccordionItem>
        </Accordion>

    </>);
}